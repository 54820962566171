<script>
export default {
  filters: {
    fileSize: function (num) {
      var base = 1024
      var prefixes = ['k', 'M', 'G', 'T']
      var exp = Math.log(num) / Math.log(base) | 0
      return (num / Math.pow(base, exp)).toFixed(1) + ' ' + ((exp > 0) ? prefixes[ exp - 1 ] + 'o' : 'Bytes')
    }
  }
}
</script>

<template>
  <div class="space-y-2 py-4 whitespace-normal">
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">UUID</div>
      <div class="w-4/5">{{ params.data.uuid }}</div>
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Titre</div>
      <div class="w-4/5">{{ params.data.title }}</div>
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Description</div>
      <div class="w-4/5" v-html="params.data.description" />
    </div>
    <!-- <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Contenus à créer</div>
      <div class="w-4/5" v-html="params.data.content_to_create" />
    </div> -->
    <div class="flex" v-if="params.data.files && params.data.files.length > 0">
      <div class="w-1/6 text-right font-bold mr-2">Pièces jointes</div>
      <div class="w-4/5">
        <ul v-for="(file, i) in params.data.files" :key="i">
          <li>
            <a :href="file.url" target="_blank" ><i class="fas fa-eye"></i> <span>{{ file.name }} ({{ file.size | fileSize}})</span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
